import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VCard,{staticClass:"mx-auto peeps-card",attrs:{"max-width":"600","outlined":""}},[_c('peeps-above'),_c(VCardText,{staticClass:"pa-sm-8"},[_c('div',{staticClass:"mb-5 text-center"},[_c('h1',[_vm._v("Create A Free Account")])]),_c('signup',{scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v("Create A Free Account")]},proxy:true}])}),_c('div',{staticClass:"text-center"},[_c(VDivider,{staticClass:"mt-3 mb-3"}),_c('p',{staticClass:"lead"},[_vm._v("Already have a Peeps account?")]),_c(VBtn,{staticClass:"text-capitalize",attrs:{"to":"/login","large":"","color":"primary"}},[_vm._v("Login")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }